import { ScenariosList } from './ScenariosList'
import { CreateScenario } from './CreateScenario'
import { EditScenario } from './EditScenario'
import { ShowScenario } from './ShowScenario'

const pages = {
  list: ScenariosList,
  create: CreateScenario,
  edit: EditScenario,
  show: ShowScenario,
}

export default pages
