import { Edit, FileInput, NumberInput, SimpleForm, TextInput, useRecordContext } from 'react-admin'
import config from '../../constants/config'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classes from './Scenes.module.sass'

const CustomAudioView = (props: any) => {
  const record = useRecordContext(props)
  return (
    <audio controls>
      <source src={config.apiUrl + '/' + record.toString()} type="audio/ogg" />
      Your browser does not support the audio element.
    </audio>
  )
}

export const EditScene = () => {
  const navigate = useNavigate()
  return (
    <Edit title="Редактирование сцены">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="name"
          label="Название"
          required={true}
          className={classes.DataFieldContainer}
        />
        <NumberInput
          source="duration"
          label="Длительность воспроизведения"
          className={classes.DataFieldContainer}
          helperText="* только для изображений (сек)"
        />
        <FileInput
          source="contentPath"
          label="Контент"
          accept={['image/jpg', 'image/jpeg', 'image/png', 'video/mp4']}
        >
          {/*<CustomAudioView source="sound" />*/}
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}
