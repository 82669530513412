import { Create, SimpleForm, TextInput, FileInput, FileField, NumberInput } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import classes from './Scenes.module.sass'

export const CreateScene = () => {
  const navigate = useNavigate()
  return (
    <Create title="Создать сцену" redirect="list">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="name"
          label="Название"
          required={true}
          className={classes.DataFieldContainer}
        />
        <NumberInput
          source="duration"
          label="Длительность воспроизведения"
          defaultValue={0}
          className={classes.DataFieldContainer}
          helperText="* только для изображений (сек)"
        />
        <FileInput
          source="contentPath"
          label="Контент"
          accept={['image/jpg', 'image/jpeg', 'image/png', 'video/mp4']}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}
