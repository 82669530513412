import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ReferenceArrayField,
  ShowButton,
  DateField,
  Show,
  SimpleShowLayout,
} from 'react-admin'
import { SceneCustomFileField } from '../scenes/components/SceneCustomFileField'
import classes from '../scenes/Scenes.module.sass'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const ShowScenario = () => {
  const navigate = useNavigate()

  return (
    <Show>
      <SimpleShowLayout>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextField source="name" label="Название" cellClassName={classes.DataFieldContainerSmall} />
        <ReferenceArrayField reference="scenes" source="scenes" label="Сцены">
          <Datagrid bulkActionButtons={false}>
            <TextField
              source="name"
              label="Название"
              cellClassName={classes.DataFieldContainerSmall}
            />
            <SceneCustomFileField source="contentPath" label="Файл" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
        <DateField source="startDatetime" label="Дата начала" showTime={true} />
        <DateField source="endDatetime" label="Дата окончания" showTime={true} />
        <EditButton />
      </SimpleShowLayout>
    </Show>
  )
}
