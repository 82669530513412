import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ReferenceArrayField,
  ShowButton,
  DateField,
} from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import { SceneCustomFileField } from '../scenes/components/SceneCustomFileField'
import classes from '../scenes/Scenes.module.sass'

export const ScenariosList = () => {
  return (
    <List title="Список сценариев" empty={false} pagination={<CustomPagination />} exporter={false}>
      <Datagrid>
        <TextField source="name" label="Название" cellClassName={classes.DataFieldContainerSmall} />
        <DateField source="startDatetime" label="Дата начала" showTime={true} />
        <DateField source="endDatetime" label="Дата окончания" showTime={true} />
        {/* <ReferenceArrayField reference="scenes" source="scenes" label="Сцены">
          <Datagrid bulkActionButtons={false}>
            <TextField
              source="name"
              label="Название"
              cellClassName={classes.DataFieldContainerSmall}
            />
            <SceneCustomFileField source="contentPath" label="Файл" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField> */}
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}
